.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.transfer {
  width: 1000px;
}

.detailPanel {
  width: 900px;
}

.roles{
  width: 300px;
}

.change{
  height: 700px;
}

.swal-footer {
  background-color: rgb(245, 248, 250);
  margin-top: 32px;
  border-top: 1px solid #E9EEF1;
  justify-content: center;
  text-align: center;
  display: flex;
}

.swal-overlay {
  background-color: rgba(55, 56, 56, 0.45);
}